
import styled from "styled-components";
import lock from './assets/manta/lock.png'
import manta from './assets/manta/manta.png';
import Rectangle2 from './assets/manta/Rectangle2.png';
import { useWindowSize } from "react-use";

const Container=styled.div`
width:100% ;
position:relative ;
height:100vh;
padding-top:80px ;
background: linear-gradient(87deg,#FCF1F8 6.32% , #F0F1FD 91.52%);
/* height:calc(100vh - 80px) ; */
/* margin-top:80px ; */
overflow:hiddren ;

 `
 const Cover4=styled.div` 
 position: absolute;
 width:100% ;
 height:100% ;
 right: 0;
 top: 0;
 background-image:url(${manta}) ;
 background-repeat:no-repeat;
 background-size:cover;
 `
 const Cover3=styled.img` 
 position: absolute;
 width:auto;
 height:100%;
 right: 0;
 top: 0;
 /* top: 0;
 left: 0;
 
 bottom: 0; */
 z-index:99;
 /* background-image:url(${Rectangle2}) ; */
 @media screen and (max-width:450px){
  top:30px;
  width:180%;
  height:auto;
 }
 `

const Inner=styled.div`
width:1280px ;
margin:0 auto ;
position:relative ;


@media screen and (max-width:1280px){
   width:100% ;
}

@media screen and (max-width:950px){
   main{
    width:100% ;
    display:flex ;
    justify-content:center ;
   }
}

 `

 const Inner2=styled(Inner)`
 position:absolute ;
 top:0 ;
 width:100%;
 height:100%;
 z-index:200;

 @media screen and (max-width:1500px){
    left:80px ;
  }

  @media screen and (max-width:1280px){
   left:0 ;
  }
 `
const Desc=styled.div`
  width:570px ;
  padding-top:262px ;
  text-align:left ;
  font-size:50px ;
  font-family: 'RedHatDisplay-Regular';
  line-height: 60px;
  z-index:400;

  i{
    font-style:normal ;
  }

  aside{
    padding-right:76px ;
  }
  @media screen and (max-height:770px) and (min-width:1002px) {
    padding-top:202px ;
}
@media screen and (max-height:670px) and (min-width:1002px){
    padding-top:152px ;
}
  @media screen and (max-width:1500px){
    width:495px ;
    font-size:46px ;
    line-height: 60px;
  }

  @media screen and (max-width:1280px){
   padding-left:117px ;
   box-sizing:content-box ;
  }

  @media screen and (max-width:900px){
  /* width:100% ;
  height:auto ; */
  padding-left:calc(50% - 235px);
  padding-top:322px ;
}

/* 
 @media screen and (max-width:1180px){
    width:435px ;
    font-size:40px ;
    line-height: 54px;

    aside{
    padding-right:26px ;
  }
 } */
/* 
 @media screen and (max-width:950px){
    box-sizing:border-box ;
    padding-left:0px ;
 }

*/
@media screen and (max-width:750px){
  width:290px;
  font-size:30px ;
  padding-left:calc(50% - 145px);
  line-height: 50px;
  } 
`

// const PurpleText=styled.div`
// color:#60DFFF;
// font-family: 'Galyon-Book';
// text-align:left ;
// white-space: pre-wrap;
// `

const Data=styled.div`
position:absolute ;
bottom:62px ;
margin-top:203px;
text-align:left ;
z-index:9999 ;

@media screen and (max-width:750px){
    bottom:39px ;
}
@media screen and (max-width:450px){
    bottom:100px ;
}
/* padding-top:25% ; */
 `

const Footer=styled.div`
/* display:inline-flex ; */
width:100% ;
margin-top: 14px;
font-size: 17px;
font-family: 'RedHatMono-Regular';
font-weight: normal;
line-height: 60px;
display:flex ;
align-items:center ;
padding:23px 5vw 0 5vw;
/* white-space:pre-wrap ; */
white-space:nowrap;
word-spacing:5px;

&>img{
  width:30px ;
  height:auto ;
}

&>mark::before{
  content: "&";
  font-family: 'RedHatMono-Regular';
  margin:0 10px ;
}

@media screen and (max-width:750px){
    font-size:14px ;
    line-height:13px ;

    img.lock{
      width:25px ;
      height:25px !important ;
    }
}

 `
 const Cover=styled.div`
 position:fixed;
  top:0;
  right:0;
  z-index:9999;

  width:100%;
  height:100vh;
 `

// const MantaPicture = styled.img`
// position:absolute;
// right:0 ;
// top:0;

// /* @media screen and (max-height:870px){
//   width:750px;
// }
// @media screen and (max-height:770px){
//   width:650px;
// }
// @media screen and (max-height:670px){
//   width:580px;
// } */
// @media screen and (max-width:1100px){
//   /* width:100% ;
//   height:auto ; */
// width:350px ;
//  height:auto;
//  left:50% ;
//  transform:translateX(-50%);
// }
// `


// Naming Service 
// on Mantle For Wallets, 
// Websites, and more
export function Linea(props){
  const {onEnterNextPage}=props;
  const {width}=useWindowSize()

  /**
   * Naming Service on 
Manta For Wallets, 
Websites, and more
   */
    return (
      <Container>
        {/* <MantaPicture src={manta} alt="" /> */}
        {/* <Cover3 src={manta}></Cover3> */}
        
        {
        //  (width<= 450 || width>1100) && <Cover3 src={manta}></Cover3>
        (width<= 450) && <Cover3 src={manta}></Cover3>
        }
        {
          // width>450 && width<=1100 && <Cover4></Cover4>
          width>450 && <Cover4></Cover4>
        }
         {/* {
         width<= 450 && <Cover3 src={manta}></Cover3>
        } */}
            <Inner>
              <Inner2>
                <main>
                    <Desc>
                    Naming Service on <br />
                        <section style={{display:"flex",alignItems:'center'}}>
                                {/* <mark style={{color:"#60DFFF",background:"transparent",whiteSpace:"pre-wrap"}}>naming for </mark>
                                wallets */}
                                Manta For Wallets,
                        </section>
                        Websites, and more
                        {/* <aside style={{width:"100%",textAlign:"right",}}>websites</aside>
                        <aside style={{width:"100%",textAlign:"right",color:"#737373"}}>on Linea</aside> */}
                    </Desc>
                </main>
                <Cover>

              </Cover>
              </Inner2>
                 
            </Inner>
            <Data>
              {/* <LogoCard /> */}
              <Footer onClick={(e)=>onEnterNextPage(e)}>
                {/* Omni-chain Solution Powered  */}
                Powered  by  
                <img className="lock" src={lock} alt="" />
                LayerZero
                {/* <mark style={{color:"#60DFFF",background:"transparent",whiteSpace:"pre-wrap"}}></mark>
                <img style={{height:'16px',width:'auto',marginBottom:'5px'}} src={linea} alt="" /> */}
              </Footer>
          </Data>
      </Container>
    )
}




